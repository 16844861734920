<template>
  <CategoriesTemplate :loading="true">
    <template v-slot:list-items>
      <li v-for="item in items" :key="item" class="categories__item">
        <Rectangle
          :height="{
            xs: 5,
            md: 6,
            lg: 5,
            xl: 6,
            xxl: 7,
          }"
          theme="dark"
        />
      </li>
    </template>
  </CategoriesTemplate>
</template>

<script>
import CategoriesTemplate from './categories-template.vue';
import Rectangle from '../skeleton/rectangle.vue';

export default {
  name: 'CategoriesLoader',
  components: {
    CategoriesTemplate,
    Rectangle,
  },
  data: () => ({
    items: Array.from({ length: 10 }, (x, i) => i),
  }),
};
</script>
