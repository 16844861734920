import GoogleMapsLoader from 'google-maps';
import config from './config';

GoogleMapsLoader.VERSION = '3.39';
GoogleMapsLoader.REGION = 'PL';
GoogleMapsLoader.LANGUAGE = 'pl';
GoogleMapsLoader.LIBRARIES = ['geometry'];
GoogleMapsLoader.KEY = config('googleMapsApiKey');

export default GoogleMapsLoader.load;
