<template>
  <SkeletonBase
    :height="height"
    :theme="theme"
    :fit-to-parent="fitToParent"
    base-class="rectangle"
  >
    <div slot-scope="{ classes }" :class="classes">
      <!-- rectangle -->
    </div>
  </SkeletonBase>
</template>
<script>
import SkeletonBase from './skeleton-base';
import props from './props';

export default {
  name: 'Rectangle',
  components: {
    SkeletonBase,
  },
  mixins: [props],
};
</script>
