<template>
  <div class="images-grid">
    <div v-for="(row, index) in rows" :key="index" class="images-grid__row">
      <div
        v-for="(orientation, i) in row"
        :key="i"
        :class="getClasses(orientation, row.length)"
      >
        <Rectangle :fit-to-parent="fixedLayout" :height="6" theme="dark" />
      </div>
    </div>
  </div>
</template>

<script>
import Rectangle from '../skeleton/rectangle.vue';

export default {
  name: 'ImagesGridSkeleton',
  components: {
    Rectangle,
  },
  props: {
    fixedLayout: {
      type: Boolean,
      required: true,
    },
    rowsCount: {
      type: Number,
      required: false,
      default: 5,
    },
  },
  computed: {
    fitToParent() {
      return this.fixedLayout;
    },
    rows() {
      const presets = {
        even: ['portrait', 'landscape', 'portrait'],
        odd: ['portrait', 'landscape', 'portrait'],
      };

      return Array.from({ length: this.rowsCount }, (x, i) => i).map(index => {
        const presetName = index % 2 === 0 ? 'odd' : 'even';
        const preset = presets[presetName];

        if (index === this.rowsCount - 1) {
          preset.push('landscape');
        }

        return preset;
      });
    },
  },
  methods: {
    getClasses(orientation, inRow) {
      return {
        'images-grid__item': true,
        [`images-grid__item--${orientation}`]: true,
        [`images-grid__item--in-row-${inRow}`]: true,
        'images-grid__item--fixed': this.fixedLayout,
        'images-grid__item--fluid': !this.fixedLayout,
      };
    },
  },
};
</script>
