import loadGoogleMaps from '../utils/googleMapsLoader';

/**
 * Vue.js plugin that loads Google Maps API and adds the following instance properties:
 * - this.$loadGoogleMaps - a reference to GoogleMapsLoader.load
 *   loads a map and executes a callback, or just executes a callback if map is already loaded
 * - this.$google - a reference to window.google object
 *   that's added by Google Maps API when the API is loaded
 * - this.mapLoaded - a callback that executes when Google Maps API is loaded for the first time.
 */
const VueGoogleMaps = {
  install(Vue) {
    Vue.mixin({
      beforeCreate() {
        this.$loadGoogleMaps = loadGoogleMaps;

        loadGoogleMaps(google => {
          this.$google = google;
        });

        if (typeof this.$options.mapLoaded === 'function') {
          loadGoogleMaps(google => {
            this.$options.mapLoaded(google);
          });
        }
      },
    });
  },
};

export default VueGoogleMaps;
