var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VueAutosuggest',{ref:"autocomplete",class:_vm.autosuggestClass,attrs:{"get-suggestion-value":_vm.getValue,"input-props":{
    id: _vm.randomId,
    placeholder: 'Szukaj miejsc, widokow, lokalizacji…',
    autocomplete: 'nope',
    class: 'autosuggest__input',
  },"suggestions":[
    {
      data: _vm.options,
    } ],"should-render-suggestions":_vm.shouldRenderSuggestions},on:{"selected":_vm.onSelected,"input":_vm.inputOnChange},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var suggestion = ref.suggestion;
return [(suggestion.item.tooShort)?[_c('span',{staticClass:"autosuggest__item-label"},[_vm._v(" Wpisz co najmniej 3 znaki. ")])]:[_c('span',{staticClass:"autosuggest__item-label"},[_vm._v(" "+_vm._s(suggestion.item.name)+" ")]),_c('span',{staticClass:"autosuggest__item-count"},[_vm._v(" "+_vm._s(suggestion.item.count)+" zdjęć ")])]]}}]),model:{value:(_vm.searchPhrase),callback:function ($$v) {_vm.searchPhrase=$$v},expression:"searchPhrase"}},[_c('template',{slot:"after-input"},[_c('button',{staticClass:"autosuggest__submit",on:{"click":_vm.searchButtonOnClick}},[_c('span',{staticClass:"sr-only"},[_vm._v(" Szukaj ")]),_c('span',{class:_vm.submitIconClass},[_c('span',{staticClass:"submit-icon__circle"}),_c('span',{staticClass:"submit-icon__arrow"})])])])],2)}
var staticRenderFns = []

export { render, staticRenderFns }