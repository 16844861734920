<template>
  <div class="home">
    <header class="home__banner">
      <home-banner />
    </header>
    <!-- /.home__banner -->

    <div class="home__categories">
      <div class="container">
        <!-- eslint-disable-next-line -->
        <h2
          class="text text--xs-header-3 text--sm-header-2 text--xs-center text--sm-strong text--sm-mt-1 text--sm-mb-2"
        >
          Wybrane Kategorie
        </h2>

        <categories :loading="categoriesLoading" :categories="categories" />

        <div v-if="!allCategoriesLoaded" class="home__more-categories">
          <button
            :class="{
              'button--loading': categoriesLoading,
            }"
            class="button button--strong"
            @click.once="fetchAllCategories"
          >
            Pokaż wszystkie
          </button>
        </div>
      </div>
      <!-- /.container -->
    </div>
    <!-- home__categories -->

    <div class="home__products">
      <div class="container">
        <featured-products />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Categories from '../categories/categories.vue';
import FeaturedProducts from '../featured-products/featured-products.vue';
import homeBanner from '../home-banner/home-banner.vue';

export default {
  name: 'Home',
  components: {
    homeBanner,
    Categories,
    FeaturedProducts,
  },
  data() {
    return {
      categoriesLoading: true,
      allCategoriesLoaded: false,
    };
  },
  metaInfo: {
    titleTemplate: '%s',
    title: 'fotoregiony.pl — Ponad 6 000 profesjonalnych zdjęć z Beskidów',
  },
  computed: {
    ...mapState('home', ['backgroundImage']),
    ...mapState('categories', ['categories']),
    ...mapState('search', ['searchPhrase']),
  },
  created() {
    // this.fetchBackgroundImage();
    this.fetchFeaturedCategories();
  },
  methods: {
    ...mapActions('home', ['fetchBackgroundImage']),
    fetchFeaturedCategories() {
      this.$store.dispatch('categories/fetchFeaturedCategories').then(() => {
        this.categoriesLoading = false;
      });
    },
    fetchAllCategories() {
      this.categoriesLoading = true;
      this.$store.dispatch('categories/fetchAllCategories').then(() => {
        this.categoriesLoading = false;
        this.allCategoriesLoaded = true;
      });
    },
  },
};
</script>
