import breakpoint from '../utils/breakpoint';

export default {
  install(Vue) {
    Vue.mixin({
      mounted() {
        // eslint-disable-next-line
        Vue.prototype.$isMobile = () => breakpoint.is(['xs', 'sm', 'md', 'lg', 'xl']);
      },
    });

    // eslint-disable-next-line
    Vue.prototype.$isMobile = () => false;
  },
};
