<template>
  <div class="image-actions">
    <div class="image-actions__toolbar">
      <WishlistButton
        :id="id"
        :class="{ 'image-actions__button--compact-mode': compactMode }"
        class="image-actions__button image-actions__button--wishlist"
      >
        <template #default="{ inWishlist, text }">
          <span
            role="presentation"
            :class="{
              'image-actions__ico--heart': inWishlist,
              'image-actions__ico--heart-outline': !inWishlist,
            }"
            class="image-actions__ico"
          >
            {{ text }}
          </span>

          <span class="image-actions__tooltip">
            {{ text }}
          </span>
        </template>
      </WishlistButton>

      <button
        :class="{
          'image-actions__button--compact-mode': compactMode,
          'image-actions__button--loading': cartProcessing,
        }"
        class="image-actions__button image-actions__button--cart"
        @click.prevent="cartOnClick"
      >
        <span
          :class="{
            'image-actions__cart-text--hidden': hideCartText,
          }"
          class="image-actions__cart-text"
        >
          {{ cartButtonText }}
        </span>

        <span
          :class="{
            'image-actions__ico--remove': inCart,
            'image-actions__ico--cart': !inCart,
          }"
          class="image-actions__ico"
        >
          Koszyk
        </span>

        <span v-if="hideCartText" class="image-actions__tooltip">
          {{ cartButtonText }}
        </span>
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import WishlistButton from '../wishlist-button/wishlist-button.vue';

export default {
  name: 'ImageActions',
  components: { WishlistButton },
  props: {
    id: {
      type: Number,
      required: true,
    },
    hideCartText: {
      type: Boolean,
      default: false,
    },
    compactMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      cartProcessing: false,
    };
  },
  asyncComputed: {
    productsInCart: {
      get() {
        return this.$store.getters['cart/products'];
      },
      default: [],
    },
  },
  computed: {
    ...mapGetters('wishlist', {
      productsIdsInWishlist: 'ids',
    }),
    inCart() {
      return this.productsInCart.filter(({ id }) => id === this.id).length > 0;
    },
    cartButtonText() {
      return this.inCart ? 'Usuń z koszyka' : 'Dodaj do koszyka';
    },
  },
  methods: {
    ...mapActions('cart', {
      addToCart: 'add',
      removeFromCart: 'remove',
    }),
    async cartOnClick() {
      const callbacks = {
        addToCart: () =>
          this.addToCart({
            id: this.id,
            size: 'md',
          }),
        removeFromCart: () => this.removeFromCart(this.id),
      };

      const callback = this.inCart
        ? callbacks.removeFromCart
        : callbacks.addToCart;

      this.cartProcessing = true;
      await callback();
      this.cartProcessing = false;
    },
  },
};
</script>
