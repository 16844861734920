import isNumber from 'lodash/isNumber';
import isObject from 'lodash/isObject';
import isString from 'lodash/isString';
import every from 'lodash/every';

export default {
  props: {
    theme: {
      type: String,
      default: 'light',
      validator(theme) {
        const themes = ['light', 'dark'];

        return isString(theme) && themes.includes(theme);
      },
    },
    animated: {
      type: Boolean,
      required: false,
      default: true,
    },
    height: {
      required: false,
      default: 1,
      validator(prop) {
        if (isNumber(prop)) {
          return true;
        }

        if (!isObject(prop)) {
          return false;
        }

        const validProps = ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'];

        /* eslint-disable max-len */
        return every(
          prop,
          (value, breakpoint) =>
            validProps.includes(breakpoint) && isNumber(value)
        );
      },
    },
    fitToParent: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
