import sortBy from 'lodash/sortBy';
import api from '../../api/api';
import { getChildProducts, normalizeMoltinProduct } from '../../moltin/helpers';

const state = {
  product: {},
  products: [],
  childProducts: [],
  productsTotal: 0,
};

const mutations = {
  setProduct(state, data) {
    state.product = data;
  },
  appendProducts(state, { data }) {
    data.forEach(product => {
      state.products.push(product);
    });
  },
  setProductsTotal(state, { total }) {
    state.productsTotal = total;
  },
  setChildProducts(state, childProducts) {
    state.childProducts = childProducts;
  },
};

const getters = {
  getAttributeName: state => attribute => state.product[attribute].name,
  getAttributeCount: state => attribute => state.product[attribute].count,
  products: state => state.products,
  childProducts: state => state.childProducts,
  productsCount: state => state.products.length,
  productsTotal: state => state.productsTotal,
};

const actions = {
  async fetchProduct({ commit }, { slug }) {
    const { data } = await api.fetch('productBySlug', slug);
    const childProducts = await getChildProducts(data.id);
    const childProductsNormalized = await Promise.all(
      childProducts.map(childProduct =>
        normalizeMoltinProduct(childProduct.data)
      )
    );

    commit('setProduct', data);
    commit('setChildProducts', sortBy(childProductsNormalized, 'width'));
  },
  async fetchRelatedProducts({ commit }, { offset = 0, perPage = 10, types }) {
    const taxonomyQuery = [
      {
        type: types,
      },
    ];

    const params = {
      offset,
      per_page: perPage,
    };

    const response = await api.fetch('postsByTaxonomy', taxonomyQuery, params);

    commit('appendProducts', response);
    commit('setProductsTotal', response);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
