var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cart-wrapper",class:_vm.cartWrapperClasses},[_c('div',{staticClass:"cart-wrapper__content"},[(_vm.showColumnHeaders)?_c('div',{staticClass:"cart-table-headers"},[_vm._m(0)]):_vm._e(),_c('ul',{staticClass:"cart-list",class:_vm.cartListClasses},_vm._l((_vm.products),function(ref){
        var id = ref.id;
        var slug = ref.slug;
        var name = ref.name;
        var price = ref.price;
        var image = ref.image;
        var size = ref.size;
        var width = ref.width;
        var height = ref.height;
        var siblings = ref.siblings;
return _c('li',{key:id,staticClass:"cart-list__item"},[_c('ul',{staticClass:"cart-list__props"},[_c('li',{staticClass:"cart-list__prop cart-list__prop--image"},[_c('router-link',{staticClass:"cart-list__link",attrs:{"to":_vm.getSingleProductRoute(slug)}},[_c('img',{staticClass:"cart-list__image",attrs:{"src":image,"alt":name}})])],1),(_vm.showTitles)?_c('li',{staticClass:"cart-list__prop cart-list__prop--name"},[_c('router-link',{staticClass:"cart-list__link",attrs:{"to":_vm.getSingleProductRoute(slug)}},[_vm._v(" "+_vm._s(name)+" ")])],1):_vm._e(),_c('li',{staticClass:"cart-list__prop cart-list__prop--id"},[_vm._v("ID: "+_vm._s(id))]),_c('li',{staticClass:"cart-list__prop cart-list__prop--size"},[_c('label',{staticClass:"sr-only",attrs:{"for":slug}},[_vm._v(" Rozmiar ")]),_c('select',{staticClass:"cart-list__select",attrs:{"id":slug,"name":slug},on:{"change":function($event){return _vm.sizeOnChange(id, $event.target.value)}}},_vm._l((siblings),function(sibling){return _c('option',{key:sibling.moltin.id,domProps:{"selected":sibling.size === size,"value":sibling.size}},[_vm._v(" "+_vm._s(_vm.productSizeToHumanReadable(sibling.size))+" — "+_vm._s(sibling.width)+"x"+_vm._s(sibling.height)+"px "),(!_vm.loading && sibling.size !== size)?[_vm._v(" ("+_vm._s(sibling.price)+") ")]:_vm._e()],2)}),0)]),_c('li',{staticClass:"cart-list__prop cart-list__prop--price"},[_vm._v(" "+_vm._s(price)+" ")]),_c('li',{staticClass:"cart-list__prop cart-list__prop--delete"},[_c('button',{staticClass:"cart-list__delete",on:{"click":function($event){return _vm.deleteOnClick(id)}}},[_vm._v(" Usuń ")])])])])}),0)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',{staticClass:"cart-table-headers__list"},[_c('li',{staticClass:"cart-table-headers__item cart-table-headers__item--image"},[_vm._v(" Podgląd ")]),_c('li',{staticClass:"cart-table-headers__item cart-table-headers__item--name"},[_vm._v(" Tytuł ")]),_c('li',{staticClass:"cart-table-headers__item cart-table-headers__item--id"},[_vm._v(" ID ")]),_c('li',{staticClass:"cart-table-headers__item cart-table-headers__item--size"},[_vm._v(" Rozmiar ")]),_c('li',{staticClass:"cart-table-headers__item cart-table-headers__item--price"},[_vm._v(" Cena ")]),_c('li',{staticClass:"cart-table-headers__item cart-table-headers__item--delete"},[_c('span',{staticClass:"sr-only"},[_vm._v("usuń")])])])}]

export { render, staticRenderFns }