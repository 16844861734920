import find from 'lodash/find';
import get from 'lodash/get';
import concat from 'lodash/concat';
import api from '../../api/api';

/* eslint-disable camelcase */

const state = {
  categories: [],
  loading: false,
};

const getters = {
  categories: state => state.categories,
  loading: state => state.loading,
};

const mutations = {
  setCategories(state, categories) {
    state.categories = categories;
  },
  addCategories(state, categories) {
    // Loop through all newly queried categories
    // Compare their ids with existing categories
    // And return an array of these ones that are really new
    const newCategories = categories.filter(
      category =>
        !find(state.categories, newCategory => newCategory.id === category.id)
    );
    state.categories = concat(state.categories, newCategories);
  },
  setLoading(state, isEnabled) {
    state.loading = isEnabled;
  },
};

const parseResponse = data =>
  data.map(category => {
    // TODO: provide the fallback in the backend
    const image = get(category, 'attributes.thumbnail') || {
      tiny: {
        url: '',
      },
      large: {
        url: '',
      },
    };

    return {
      image,
      id: category.id,
      name: category.name,
      slug: category.slug,
      featured: category.featured === true,
    };
  });

const actions = {
  async fetchFeaturedCategories({ commit }) {
    commit('setLoading', true);

    const { data } = await api.fetch('terms', 'type', {
      metaQuery: {
        featured: 1,
      },
    });

    commit('setLoading', false);
    commit('setCategories', parseResponse(data));
  },
  async fetchAllCategories({ commit }) {
    commit('setLoading', true);

    const { data } = await api.fetch('terms', 'type', {
      per_page: 99999,
    });

    commit('setLoading', false);
    commit('addCategories', parseResponse(data));
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
