<template>
  <div class="home-banner">
    <div class="container">
      <div class="row">
        <div class="offset-lg-1 offset-xxl-2 col-12 col-lg-10 col-xxl-8">
          <h1 class="home-banner__header">
            Ponad <strong class="home-banner__num">30 000</strong>
            profesjonalnych zdjęć z Beskidów do użytku komercyjnego
          </h1>

          <div class="home-banner__search">
            <search-form size="large" />
          </div>
          <!-- /.home-banner__search -->
        </div>
        <!-- /.col -->
      </div>
      <!-- /.container -->
    </div>
    <!-- .row -->
  </div>
  <!-- .home-banner -->
</template>

<script>
import searchForm from '../search-form/search-form.vue';

export default {
  name: 'HomeBanner',
  components: {
    searchForm,
  },
};
</script>
