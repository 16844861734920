import sortBy from 'lodash/sortBy';
import orderBy from 'lodash/orderBy';
import api from '../../api/api';
import mapTaxonomies from '../utils/mapTaxonomies';

const dummyTerm = Object.freeze({
  slug: '',
  name: '',
  count: 0,
});

const state = {
  'view-on': [],
  'view-from': [],
  'time-of-day': [],
  type: [],
  city: [],
  place: [],
  season: [],
  searchTerms: [],
  products: [],
  total: 0,
  pages: 0,
};

const mutations = {
  setSearchTerms(state, { searchTerms, search }) {
    state.searchTerms = sortBy(searchTerms, ({ name }) => {
      const tagNameLowerCase = name.toLowerCase();
      return tagNameLowerCase.indexOf(search);
    }).map(({ name, slug, count }) => ({
      name,
      slug,
      count,
    }));
  },
  resetSearchTerms(state) {
    state.searchTerms = [];
  },
  setTerms(state, { taxonomy, terms }) {
    // Add state property to each term and sort terms by name
    state[taxonomy] = orderBy(
      terms.map(term => ({ ...term, checked: false })),
      'name'
    );
  },
  setProducts(state, { data, total, pages }) {
    state.products = data;
    state.total = total;
    state.pages = pages;
  },
  setCategories(state, { data }) {
    state.categories = data;
  },
};

const getters = {
  // TODO: remove.
  getSearchTermBySlug: state => slug => {
    const found = state.searchTerms.find(term => term.slug === slug);

    if (!found) {
      return dummyTerm;
    }

    return found;
  },
  getTermName: state => (taxonomy, termSlug) => {
    const terms = state[taxonomy];

    if (terms.length === 0) {
      return '';
    }

    return terms.filter(term => term.slug === termSlug).pop().name;
  },
};

const actions = {
  async fetchSearchTerms({ commit, dispatch }, search) {
    /* eslint-disable camelcase */
    const { data } = await dispatch('fetchTerms', {
      taxonomy: 'all',
      args: {
        search,
      },
    });

    commit('setSearchTerms', {
      search,
      searchTerms: data,
    });
  },
  async fetchTerms({ commit }, { taxonomy, args }) {
    /* eslint-disable camelcase */

    const response = await api.fetch('terms', taxonomy, {
      per_page: 999,
      hide_empty: true,
      ...args,
    });

    commit('setTerms', {
      taxonomy,
      terms: response.data,
    });

    return response;
  },
  async fetchProducts(
    { commit },
    {
      taxonomies = {},
      perPage = 20,
      page = 1,
      geoQuery = {},
      searchQuery = '',
      premiumOnly = false,
    }
  ) {
    const taxonomyQuery = mapTaxonomies({
      ...taxonomies,
      quality: premiumOnly ? 'premium' : false,
    });

    const response = await api.fetch('postsByTaxonomy', taxonomyQuery, {
      per_page: perPage,
      page,
      geoQuery,
      searchQuery,
    });

    commit('setProducts', response);

    return response;
  },
  resetSearchTerms({ commit }) {
    commit('resetSearchTerms');
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
