import axios from 'axios';
import qs from 'qs';
import has from 'lodash/has';
import responseParser from './responseParser';
import config from '../utils/config';
import Cache from '../cache';
import ExtendableError from '../utils/ExtendableError';
import bugsnagClient from '../utils/bugsnag';

/* eslint-disable camelcase */

const apiCaller = axios.create({
  baseURL: `${config('wpUrl')}/${config('wpApiUri')}`,
  paramsSerializer(params) {
    return qs.stringify(params, {
      arrayFormat: 'indices',
    });
  },
});

const namespace = config('wpApiNamespace');

class ApiError extends ExtendableError {}

const api = {
  terms(taxonomy, params = {}) {
    return apiCaller.get(`${namespace}/${taxonomy}`, {
      params,
    });
  },
  products(params) {
    return apiCaller.get(`${namespace}/products/`, {
      params,
    });
  },
  productsByIds(ids) {
    if (ids.length === 0) {
      return Promise.reject();
    }

    return apiCaller.get(`${namespace}/products/`, {
      params: {
        include: ids,
        per_page: 100,
      },
    });
  },
  /**
   * @param {string} slug - post slug
   * @return {AxiosPromise}
   */
  productBySlug(slug) {
    return apiCaller.get(`${namespace}/products/`, {
      params: {
        slug,
        per_page: 1,
      },
    });
  },
  /**
   * @param {Number} id
   * @return {AxiosPromise}
   */
  productById(id) {
    return apiCaller.get(`${namespace}/products/${id}`);
  },
  postsByTaxonomy(taxonomyQuery, params = {}) {
    return apiCaller.get(`${namespace}/products/`, {
      params: {
        taxonomyQuery,
        ...params,
      },
    });
  },
  /**
   * @return {AxiosPromise}
   */
  term(taxonomy, term) {
    return apiCaller.get(`/app/v1/term-data/${taxonomy}/${term}/`);
  },

  frontPage() {
    return apiCaller.get('/app/v1/front-page/');
  },
};

const cache = Cache('api');

export default {
  /**
   * @param {string} func
   * @param {object} args
   * @returns {Promise}
   */
  async fetch(func, ...args) {
    if (!has(api, func)) {
      throw new Error(`api has no ${func} method`);
    }

    const getHeaderValue = (headers, value, def = 0) => {
      if (!has(headers, value)) {
        return def;
      }

      return headers[value];
    };

    const cached = cache.get(func, args);

    if (cached) {
      return cached;
    }

    try {
      const response = await api[func](...args);
      const parser = responseParser(func);
      const success = response.status === 200;
      const data = success ? parser.parse(response) : [];
      const total = parseInt(
        getHeaderValue(response.headers, 'x-wp-total'),
        10
      );
      const pages = parseInt(
        getHeaderValue(response.headers, 'x-wp-totalpages'),
        10
      );

      const returnValue = {
        data,
        total,
        pages,
        success,
        error: !success,
      };

      cache.set(func, args, returnValue);

      return returnValue;
    } catch (e) {
      bugsnagClient.notify(e);
      throw new ApiError('Unable to process the request');
    }
  },
};
