import upperFirst from 'lodash/upperFirst';
import sortBy from 'lodash/sortBy';
import moltinClient from './index';
import api from '../api/api';
import productSizeToHumanReadable from '../utils/productSizeToHumanReadable';
import bugsnagClient from '@/vue/utils/bugsnag';
import cache from './cache';

export const addProduct = async productId => {
  const { moltin, cartId } = await moltinClient();
  return moltin.Cart(cartId).AddProduct(productId, 1);
};

export const getProduct = async id => {
  const cacheKey = 'product';
  const cached = cache.get(cacheKey, id);

  if (cached) {
    return cached;
  }

  const { moltin } = await moltinClient();
  const product = await moltin.Products.Get(id);

  cache.set(cacheKey, id, product);

  return product;
};

export const getProducts = async (ids = []) => {
  const cacheKey = 'child-products';
  const cached = cache.get(cacheKey, ids);

  if (cached) {
    return cached;
  }

  const { moltin } = await moltinClient();
  const products = Promise.all(
    ids.map(async productsIds => moltin.Products.Get(productsIds))
  );

  cache.set(cacheKey, ids, products);

  return products;
};

export const removeProduct = async id => {
  const { moltin, cartId } = await moltinClient();

  const items = await moltin.Cart(cartId).Items();
  const prod = items.data.find(product => product.product_id === id);

  return moltin.Cart(cartId).RemoveItem(prod.id);
};

export const getChildProducts = async id => {
  // Get Moltin products ID based on WP product ID
  const wpProduct = await api.fetch('productById', id);
  const moltinProductId = wpProduct.data.attributes.moltin_id;

  // Get child products and find the one that size matches the `size` parameter
  const moltinProduct = await getProduct(moltinProductId);

  const childProdIds = moltinProduct.data.relationships.children.data.map(
    product => product.id
  );
  return getProducts(childProdIds);
};

export const getProductBySize = async (id, size) => {
  const childProducts = await getChildProducts(id);
  return childProducts.find(({ data }) => data.sku === `${id}-${size}`);
};

export const getCart = async () => {
  const { moltin, cartId } = await moltinClient();

  return moltin.Cart(cartId).Items();
};

export const getWpProductIdBySku = sku => {
  // Get ID from sku. sku has a format of: ${id}-${size}
  return parseInt(sku.split('-')[0], 10);
};

export const normalizeMoltinProduct = async ({
  id,
  sku,
  description,
  relationships,
  meta,
}) => {
  const wpIdProductId = getWpProductIdBySku(sku);
  const response = await api.fetch('productById', wpIdProductId);

  const getSize = (size, attribute) => {
    const { sizes } = response.data;
    const { image } = response.data.attributes;

    const mapping = {
      sm: sizes.sm,
      md: sizes.md,
      lg: sizes.lg,
      get xl() {
        const prop = `original${upperFirst(attribute)}`;
        return {
          [attribute]: image.large[prop],
        };
      },
    };

    return mapping[size][attribute];
  };

  const size = sku.split('-')[1];

  return {
    size,
    id: wpIdProductId,
    name: description,
    price: meta.display_price.with_tax.formatted,
    moltin: {
      id,
      parent: relationships.parent.data.id,
    },
    slug: response.data.slug,
    image: response.data.attributes.image.small.url,
    sizeHumanReadable: productSizeToHumanReadable(size),
    width: getSize(size, 'width'),
    height: getSize(size, 'height'),
  };
};

export const getProductsByMoltinIds = async productsIds => {
  try {
    const products = await Promise.all(
      productsIds.map(async id => {
        const moltinProduct = await getProduct(id);

        const parent = await getProduct(
          moltinProduct.data.relationships.parent.data.id
        );
        const siblingsIds = parent.data.relationships.children.data.map(
          product => product.id
        );
        const siblingProducts = await getProducts(siblingsIds);

        const productData = await normalizeMoltinProduct(moltinProduct.data);
        const siblings = await Promise.all(
          siblingProducts.map(async sibling =>
            normalizeMoltinProduct(sibling.data)
          )
        );

        return {
          ...productData,
          siblings: sortBy(siblings, 'width'),
        };
      })
    );

    return sortBy(products, 'id');
  } catch (e) {
    bugsnagClient.notify(e);
    return [];
  }
};
