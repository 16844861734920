import { crud, db } from '@/vue/utils/firebaseClient';
import api from '../../../api/api';
import eventBus from '../../../utils/eventBus';
import bugsnagClient from '../../../utils/bugsnag';
import { WISHLIST_ID } from '@/vue/store/utils/ls';

const getDefaultState = () => ({
  loading: false,
  ids: [],
  products: [],
  productsInitialized: false,
  recommendedProducts: [],
  recommendedProductsInitialized: false,
});

const state = getDefaultState();

const updateWishlist = ids => {
  const wishlistRefId = localStorage.getItem(WISHLIST_ID);
  const ref = db.ref('wishlist');
  const data = {
    ids,
  };

  const add = async () => {
    const { key } = await crud.insert(ref, data);
    localStorage.setItem(WISHLIST_ID, key);
    eventBus.$emit('wishlist-id-updated', {
      wishlistId: key,
    });
  };

  const update = async () => {
    return crud.update(ref, wishlistRefId, data);
  };

  const action = wishlistRefId ? update : add;
  return action();
};

const getters = {
  recommendedProducts: state => state.recommendedProducts,
  ids: state => state.ids,
  count: state => state.ids.length,
};

const mutations = {
  removeIds(state) {
    state.ids = [];
  },
  addProduct(state, productId) {
    state.ids.push(productId);
  },
  removeProduct(state, productId) {
    state.ids = state.ids.filter(id => id !== productId);
  },
  removeProducts(state) {
    state.products = [];
  },
  setProducts(state, { data }) {
    state.products = data;
    state.productsInitialized = true;
  },
  setRecommendedProducts(state, { data }) {
    state.recommendedProducts = data;
    state.recommendedProductsInitialized = true;
  },
  setLoading(state, { loading }) {
    state.loading = loading;
  },
  reset(state) {
    Object.assign(state, getDefaultState());
  },
};

const actions = {
  destroyWishlist({ commit }) {
    commit('setProducts', {
      data: [],
    });
    commit('setRecommendedProducts', {
      data: [],
    });
    commit('removeIds');
  },
  async addProduct({ commit, state }, productId) {
    if (state.ids.includes(productId)) {
      return;
    }

    commit('addProduct', productId);
    await updateWishlist(state.ids);
  },
  async removeProduct({ commit }, productId) {
    commit('removeProduct', productId);
    await updateWishlist(state.ids);
  },
  async fetchProductsByIds({ commit }, ids) {
    if (ids.length === 0) {
      commit('removeProducts');
      return Promise.resolve([]);
    }

    try {
      commit('setLoading', { loading: true });
      const response = await api.fetch('productsByIds', ids);
      commit('setProducts', response);
      return response;
    } catch (e) {
      bugsnagClient.notify(e);
      return [];
    } finally {
      commit('setLoading', { loading: false });
    }
  },
  async fetchRecommendedProductsByRefId({ commit }, refId) {
    const wishlistRef = db.ref('wishlist');

    try {
      commit('setLoading', { loading: true });
      const ids = await crud.get(wishlistRef, refId);
      const response = await api.fetch('productsByIds', ids);
      commit('setRecommendedProducts', response);
      return response;
    } catch (e) {
      bugsnagClient.notify(e);
      return [];
    } finally {
      commit('setLoading', { loading: false });
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
