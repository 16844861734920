var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"images-grid"},_vm._l((_vm.rows),function(row,index){return _c('div',{key:index,staticClass:"images-grid__row"},_vm._l((row),function(image){return _c('div',{key:image.slug,staticClass:"images-grid__item",class:_vm.imagesGridItemClasses(row, image),on:{"mouseenter":function($event){return _vm.$emit('imageMouseEnter', image)},"mouseleave":function($event){return _vm.$emit('imageMouseLeave', image)}}},[_c('router-link',{staticClass:"images-grid__link",attrs:{"to":{
          name: 'single-product',
          params: {
            slug: image.slug,
          },
        }}},[_c('h2',{staticClass:"images-grid__title",class:{
            'images-grid__title--truncated': _vm.compactMode,
            'images-grid__title--premium-image': image.isPremium,
          }},[_vm._v(" "+_vm._s(image.title)+" ")]),(image.isPremium)?_c('div',{staticClass:"images-grid__premium"},[_c('p',{staticClass:"premium-ico",class:{
              'premium-ico--small': !_vm.fixedLayout,
            }},[_c('span',{staticClass:"premium-ico__star"},[_vm._v(" Premium ")]),_c('span',{staticClass:"premium-ico__tooltip"},[_vm._v("Premium")])])]):_vm._e(),_c('LazyImage',{attrs:{"small-image":image.thumbnail,"large-image":image.image,"alt":image.title,"cover":true}})],1),(_vm.hasToolbar)?_c('div',{staticClass:"images-grid__image-actions",class:{
          'images-grid__image-actions--active': _vm.isToolbarActive(image.id),
        }},[_c('ImageActions',{attrs:{"id":image.id,"compact-mode":_vm.compactMode,"hide-cart-text":_vm.compactMode || image.displayMode === 'portrait'}})],1):_vm._e()],1)}),0)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }