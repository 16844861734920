import Vue from 'vue';
import { required, requiredIf } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import {
  USER_ATTRIBUTE,
  USER_ATTRIBUTES,
} from '@/vue/store/utils/userAttribute';

const defaultState = () =>
  USER_ATTRIBUTES.reduce(
    (acc, attr) => ({
      ...acc,
      [attr]: '',
    }),
    {}
  );

const vm = new Vue({
  mixins: [validationMixin],

  data: () => ({
    ...defaultState(),
    isInvoiceRequired: false,
    isEmailRequired: false,
  }),

  validations: {
    firstName: {
      required,
    },
    lastName: {
      required,
    },
    email: {
      required: requiredIf(function validator() {
        return this.isEmailRequired;
      }),
    },
    companyName: {
      required: requiredIf(function validator() {
        return this.isInvoiceRequired;
      }),
    },
    street: {
      required: requiredIf(function validator() {
        return this.isInvoiceRequired;
      }),
    },
    city: {
      required: requiredIf(function validator() {
        return this.isInvoiceRequired;
      }),
    },
    postcode: {
      required: requiredIf(function validator() {
        return this.isInvoiceRequired;
      }),
    },
    phoneNumber: {
      required: requiredIf(function validator() {
        return this.isInvoiceRequired;
      }),
    },
    vatNumber: {
      required: requiredIf(function validator() {
        return this.isInvoiceRequired;
      }),
    },
  },

  methods: {
    setAttribute({ attribute, value, shouldTouch }) {
      this[attribute] = value;

      if (shouldTouch) {
        this.$v[attribute].$touch();
      }
    },

    setRequiredState({ isInvoiceRequired, isEmailRequired }) {
      this.isInvoiceRequired = !!isInvoiceRequired;
      this.isEmailRequired = !!isEmailRequired;
    },
  },
});

const state = {
  attributes: defaultState(),
};

const actions = {
  init: async ({ dispatch, commit, rootGetters }) => {
    await dispatch('user/getUser', null, { root: true });

    const userAttributes = rootGetters['user/attributes'];
    const userEmail = rootGetters['user/email'];

    const attributes = [
      ...Object.values(USER_ATTRIBUTE).map(attribute => ({
        attribute,
        value: userAttributes[attribute],
      })),
      {
        attribute: USER_ATTRIBUTE.EMAIL,
        value: userEmail,
      },
    ];

    attributes.forEach(({ attribute, value }) => {
      commit('setAttribute', { attribute, value, shouldTouch: false });
    });
  },
};

const getters = {
  attributes: ({ attributes }) => attributes,
  $v: () => vm.$v,
};

const mutations = {
  setAttribute(state, { attribute, value, shouldTouch = true }) {
    state.attributes = {
      ...state.attributes,
      [attribute]: value,
    };

    vm.setAttribute({ attribute, value, shouldTouch });
  },

  setRequiredState(state, { isInvoiceRequired, isEmailRequired }) {
    vm.setRequiredState({ isInvoiceRequired, isEmailRequired });
  },

  touch(state, { attribute }) {
    vm.$v[attribute].$touch();
  },

  touchAll() {
    vm.$v.$touch();
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
