import has from 'lodash/has';

/**
 * @param {string} type
 * @returns {*}
 */
export default type => {
  const types = {
    allTags(response) {
      return response.data;
    },
    categories(response) {
      return response.data;
    },
    productBySlug(response) {
      return response.data[0];
    },
  };

  const parser = has(types, type) ? types[type] : response => response.data;

  return {
    parse(response) {
      return parser(response);
    },
  };
};
