import isNaN from 'lodash/isNaN';
import isLoggedIn from '@/vue/utils/isLoggedIn';
import store from '../store';
import Home from '../components/home/home.vue';
import { verifyPayment } from '../payment';
import bugsnagClient from '../utils/bugsnag';

const loadView = view => {
  return () =>
    import(/* webpackChunkName: "view-[request]" */ `../components/${view}`);
};

const getFilterProps = route => ({
  page: route.params.page ? Number(route.params.page) : 1,
  perPage: route.query.perPage ? Number(route.query.perPage) : 20,
  panoramic: route.query.panoramic === 'true' || route.query.panoramic === true,
  orientation: route.query.orientation || 'any',
  premium: route.query.premium === 'true' || route.query.premium === true,
});

const getMapProps = route => {
  const props = ['lat', 'lng', 'radius']
    .map(prop => parseFloat(route.query[prop]))
    .filter(prop => !isNaN(prop));

  if (props.length !== 3) {
    return {};
  }

  return {
    lat: parseFloat(route.query.lat),
    lng: parseFloat(route.query.lng),
    radius: parseFloat(route.query.radius),
  };
};

const stringToArray = param => {
  if (!param) {
    return [];
  }
  return param.split(',');
};

const deleteCartBeforeEnter = async (to, from, next) => {
  await store.restored;
  await store.dispatch('cart/deleteCart');

  return next();
};

const notLoggedInGuard = async (from, to, next) => {
  const isSignedIn = await isLoggedIn();
  if (!isSignedIn) {
    return next({
      name: 'sign-in',
    });
  }

  return next();
};

const loggedInGuard = async (from, to, next) => {
  const isSignedIn = await isLoggedIn();

  if (isSignedIn) {
    next({
      name: 'my-account',
    });
  }

  next();
};

export default [
  {
    path: '/',
    name: 'home',
    component: Home,
    props: {
      searchFormEnabled: false,
    },
  },
  {
    path: '/search/:page?',
    name: 'search-results',
    component: loadView('search-results/search-results.vue'),
    props: route => ({
      layout: route.query.layout,
      searchPhrase: route.query.searchPhrase || '',
      types: stringToArray(route.query.type),
      cities: stringToArray(route.query.city),
      seasons: stringToArray(route.query.season),
      places: stringToArray(route.query.place),
      timesOfDay: stringToArray(route.query['time-of-day']),
      viewsOn: stringToArray(route.query['view-on']),
      viewsFrom: stringToArray(route.query['view-from']),
      zoom: parseFloat(route.query.zoom) || 8,
      ...getMapProps(route),
      ...getFilterProps(route),
    }),
  },
  {
    path: '/category/:category/:page?',
    name: 'category',
    component: loadView('category/category.vue'),
    props: route => ({
      category: route.params.category,
      ...getFilterProps(route),
    }),
  },
  {
    path: '/categories/',
    name: 'categories-page',
    component: loadView('categories-page/categories-page.vue'),
  },
  {
    path: '/photo/:slug',
    name: 'single-product',
    component: loadView('single-product/single-product.vue'),
  },
  {
    path: '/wishlist/:listId?',
    name: 'wishlist',
    component: loadView('wishlist/wishlist.vue'),
    props: route => ({
      listId: route.params.listId || '',
    }),
    beforeEnter: async ({ params }, from, next) => {
      await store.restored;
      const { listId } = params;

      if (!listId) {
        return next();
      }

      const isMyOwnList = localStorage.getItem('wishlistId') === listId;

      if (!isMyOwnList) {
        next({
          name: 'wishlist-recommended',
          params: {
            listId,
          },
        });
      }

      return next();
    },
  },
  {
    path: '/wishlist/recommended/:listId?',
    name: 'wishlist-recommended',
    component: loadView('recommendations/recommendations.vue'),
    props: route => ({
      listId: route.params.listId || '',
    }),
  },
  {
    path: '/checkout/cart',
    name: 'checkout-cart',
    component: loadView('checkout-cart/checkout-cart.vue'),
    beforeEnter: async (to, from, next) => {
      await store.restored;

      return next();
    },
  },
  {
    path: '/checkout/auth',
    name: 'checkout-auth',
    component: loadView('auth/sign-in-or-sign-up.vue'),
    beforeEnter: loggedInGuard,
  },
  {
    path: '/checkout/payment',
    name: 'checkout-payment',
    component: loadView('checkout-payment/checkout-payment.vue'),
  },
  {
    path: '/checkout/payment/bank-transfer/:orderId',
    name: 'payment-bank-transfer',
    component: loadView('payment-bank-transfer/payment-bank-transfer.vue'),
    props: route => ({
      orderId: route.params.orderId,
    }),
    beforeEnter: deleteCartBeforeEnter,
  },
  {
    path: '/checkout/payment/deferred/:orderId',
    name: 'payment-deferred',
    component: loadView('payment-deferred/payment-deferred.vue'),
    props: route => ({
      orderId: route.params.orderId,
    }),
    beforeEnter: deleteCartBeforeEnter,
  },
  {
    path: '/checkout/payment/complete/:orderId',
    name: 'checkout-payment-complete',
    component: loadView('payment-complete/payment-complete.vue'),
    props: route => ({
      orderId: route.params.orderId,
    }),
    beforeEnter: async (to, from, next) => {
      try {
        await verifyPayment(to.params.orderId);
        return deleteCartBeforeEnter(to, from, next);
      } catch (e) {
        bugsnagClient.notify(e);
        return next({
          name: 'checkout-payment-error',
        });
      }
    },
  },
  {
    path: '/checkout/payment/error',
    name: 'checkout-payment-error',
    component: loadView('payment-error/payment-error.vue'),
  },
  {
    path: '/error/',
    name: 'error',
    component: loadView('error-404/error-404.vue'),
  },
  {
    path: '/terms-of-use/',
    name: 'terms-of-use',
    component: loadView('terms-of-use/terms-of-use.vue'),
  },
  {
    path: '/license/',
    name: 'license',
    component: loadView('license/license.vue'),
  },
  {
    path: '/privacy-policy/',
    name: 'privacy-policy',
    component: loadView('privacy-policy/privacy-policy.vue'),
  },
  {
    beforeEnter: loggedInGuard,
    path: '/auth/sign-up',
    name: 'sign-up',
    component: loadView('auth/sign-up.vue'),
  },
  {
    beforeEnter: loggedInGuard,
    path: '/auth/sign-in',
    name: 'sign-in',
    component: loadView('auth/sign-in.vue'),
  },
  {
    path: '/auth/password-reset-request',
    name: 'password-reset-request',
    component: loadView('auth/password-reset-request.vue'),
  },
  {
    path: '/auth/password-reset/:email/:code',
    name: 'password-reset',
    component: loadView('auth/password-reset.vue'),
    props: route => ({
      code: route.params.code,
      email: route.params.email,
    }),
  },
  {
    beforeEnter: notLoggedInGuard,
    path: '/my-account/',
    name: 'my-account',
    redirect: { name: 'orders' },
  },
  {
    beforeEnter: notLoggedInGuard,
    path: '/my-account/orders/',
    name: 'orders',
    component: loadView('account/my-account.vue'),
  },
  {
    beforeEnter: notLoggedInGuard,
    path: '/my-account/order/:orderId',
    name: 'order',
    component: loadView('account/order.vue'),
  },
  {
    beforeEnter: notLoggedInGuard,
    path: '/my-account/settings',
    name: 'user-settings',
    component: loadView('account/settings.vue'),
  },
  {
    path: '*',
    component: loadView('error-404/error-404.vue'),
  },
];
