<template>
  <div
    :class="{
      'cookies--hidden': isCookiesNoticeHidden,
    }"
    class="cookies"
  >
    <p class="cookies__info">
      Używamy plików cookie, aby maksymalnie ułatwić Państwu korzystanie z
      naszego serwisu internetowego, w tym także w celu dostosowania jej
      zawartości i reklam do Państwa preferencji, oferowania funkcji mediów
      społecznościowych oraz w celu analizy ruchu. Pliki cookie obejmują pliki
      związane z kierowaniem treści oraz pliki do zaawansowanej analityki.
      Więcej informacji podajemy na stronie z opisem plików cookie. Klikając
      Akceptuj, wyrażają Państwo zgodę na używanie przez nas plików cookie.
    </p>

    <router-link :to="{ name: 'privacy-policy' }" class="cookies__link">
      Dowiedz się więcej &raquo;
    </router-link>

    <button class="button button--strong" @click="buttonOnClick">
      Akceptuję politykę cookies
    </button>
  </div>
  <!-- /.cookies -->
</template>

<script>
import { COOKIE_NOTICE_HIDDEN } from '@/vue/store/utils/ls';

export default {
  name: 'Cookies',
  data: () => ({
    isCookiesNoticeHidden: false,
  }),
  created() {
    this.isCookiesNoticeHidden =
      localStorage.getItem(COOKIE_NOTICE_HIDDEN) === 'true';
  },
  methods: {
    buttonOnClick() {
      localStorage.setItem(COOKIE_NOTICE_HIDDEN, 'true');
      this.isCookiesNoticeHidden = true;
    },
  },
};
</script>
