<template>
  <button @click.prevent="wishlistOnClick">
    <slot :add="add" :remove="remove" :inWishlist="inWishlist" :text="text">
      {{ text }}
    </slot>
  </button>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'WishlistButton',

  props: {
    id: {
      type: Number,
      required: true,
    },
  },

  computed: {
    ...mapGetters('wishlist', {
      productsIdsInWishlist: 'ids',
    }),
    inWishlist() {
      return this.productsIdsInWishlist.includes(this.id);
    },
    text() {
      return this.inWishlist ? 'Usuń z ulubionych' : 'Dodaj do ulubionych';
    },
  },

  methods: {
    ...mapActions('wishlist', {
      add: 'addProduct',
      remove: 'removeProduct',
    }),
    wishlistOnClick() {
      return this.inWishlist ? this.remove(this.id) : this.add(this.id);
    },
  },
};
</script>
