import values from 'lodash/values';

const state = {
  loading: false,
  type: {},
};

const getters = {
  loadingByType(state, type) {
    return state.type[type];
  },
  loading(state) {
    return state.loading;
  },
};

const mutations = {
  setLoading(state, loading) {
    state.loading = loading;
  },
  setLoadingType(state, loading) {
    const { type } = state;

    state.type = {
      ...type,
      ...loading,
    };

    // Return true if at least one loading type is true
    state.loading = values(state.type).indexOf(false) === -1;
  },
};

const actions = {
  setLoading: ({ commit }, loading) => commit('setLoading', loading),
  setLoadingType: ({ commit }, loading) => commit('setLoadingType', loading),
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
