<template>
  <div
    :class="{ 'featured-products--loading': loading }"
    class="featured-products"
  >
    <h2 class="featured-products__header">
      Polecane zdjęcia
    </h2>

    <div class="featured-products__list">
      <Images-Grid v-if="!initialLoading" :images="products" />
      <Images-Grid-Skeleton v-if="initialLoading" :fixed-layout="true" />
    </div>

    <div v-if="buttonVisible" class="featured-products__button">
      <button
        :class="{
          'button--loading': loading,
        }"
        class="button button--strong"
        @click="fetchFeatured(productsCount)"
      >
        Pokaż więcej
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ImagesGrid from '../images-grid/images-grid.vue';
import ImagesGridSkeleton from '../images-grid/images-grid-skeleton.vue';

export default {
  name: 'FeaturedProducts',
  components: {
    ImagesGrid,
    ImagesGridSkeleton,
  },
  data() {
    return {
      loading: true,
      initialLoading: true,
    };
  },
  computed: {
    ...mapGetters('home', ['products', 'productsCount', 'productsTotal']),
    buttonVisible() {
      return this.productsCount < this.productsTotal;
    },
  },
  async created() {
    this.fetchFeatured();
  },
  methods: {
    fetchFeatured(offset = 0) {
      this.loading = true;

      this.$store.dispatch('home/fetchFeaturedProducts', offset).then(() => {
        this.loading = false;
        this.initialLoading = false;
      });
    },
  },
};
</script>
