const state = {
  loading: false,
};

const getters = {
  loading(state) {
    return state.loading;
  },
};

const mutations = {
  setLoading(state, { loading }) {
    state.loading = loading;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
};
