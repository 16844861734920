import { sha256 } from 'js-sha256';
import { gateway } from '@moltin/sdk';
import config from '../utils/config';
import ExtendableError from '../utils/ExtendableError';
import bugsnagClient from '../utils/bugsnag';
import {
  COGNITO_USER_ID_LOCAL_STORAGE_KEY,
  MOLTIN_CARD_ID_ANON,
} from '@/vue/store/utils/ls';

class MoltinClientError extends ExtendableError {}

export default async () => {
  try {
    const cartId = () => {
      const userId = localStorage.getItem(COGNITO_USER_ID_LOCAL_STORAGE_KEY);
      const cardIdKey = userId
        ? `MOLTIN_CART_ID_${userId}`
        : MOLTIN_CARD_ID_ANON;
      const id = localStorage.getItem(cardIdKey);

      if (!id) {
        const uniqueId = sha256(`${new Date().valueOf() + Math.random()}`);
        localStorage.setItem(cardIdKey, uniqueId);
        return uniqueId;
      }

      return id;
    };

    return {
      moltin: gateway({
        client_id: config('moltinClientId'),
        currency: 'PLN',
      }),
      cartId: cartId(),
    };
  } catch (e) {
    bugsnagClient.notify(e);
    throw new MoltinClientError('Unable to authenticate');
  }
};
