import get from 'lodash/get';
import has from 'lodash/has';
import axios from 'axios';
import qs from 'qs';
import config from '../utils/config';

const client = axios.create({
  baseURL: `${config('wpUrl')}/`,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
  transformRequest: [
    data =>
      qs.stringify(data, {
        arrayFormat: 'indices',
      }),
  ],
});

export const registerP24Transaction = async ({ email, amount, orderId }) => {
  const response = await client.post('transaction/register/p24', {
    order_id: `${orderId}`,
    p24_amount: amount,
    p24_email: email,
    p24_currency: 'PLN',
    p24_country: 'Poland',
    p24_url_return: `${window.location.origin}/checkout/payment/complete/${orderId}`,
  });

  const isSuccess = get(response, 'data.success') === true;
  const hasUrl = has(response, 'data.data.p24_url');

  if (!isSuccess || !hasUrl) {
    throw new Error('Unable to process the request');
  }

  return response.data.data.p24_url;
};

export const registerBankTransferTransaction = async ({
  email,
  amount,
  orderId,
  type,
}) => {
  const response = await client.post(
    `transaction/register/bank-transfer-${type}`,
    {
      order_id: orderId,
      amount,
      email,
    }
  );

  const isSuccess = get(response, 'data.success') === true;

  if (!isSuccess) {
    throw new Error('Unable to process the request');
  }

  return true;
};

export const verifyPayment = async orderId => {
  const response = await client.post(`/payment/verify`, {
    order_id: orderId,
  });

  const isSuccess = get(response, 'data.success') === true;

  if (!isSuccess) {
    throw new Error('Unable to verify the payment');
  }

  return true;
};
