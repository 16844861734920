export const USER_ATTRIBUTE = {
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  EMAIL: 'email',
  VAT_NUMBER: 'vatNumber',
  COMPANY_NAME: 'companyName',
  STREET: 'street',
  CITY: 'city',
  POSTCODE: 'postcode',
  PHONE_NUMBER: 'phoneNumber',
};

export const USER_ATTRIBUTES = Object.values(USER_ATTRIBUTE);
