import Auth from '@/vue/utils/amplify-auth';

export default async () => {
  try {
    const userInfo = await Auth.currentUserInfo();
    return userInfo && !!userInfo.id;
  } catch (e) {
    return false;
  }
};
