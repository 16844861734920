import dayjs from 'dayjs';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import axios from 'axios';
import Auth from '@/vue/utils/amplify-auth';
import config from '@/vue/utils/config';
import { getWpProductIdBySku } from '@/vue/moltin/helpers';
import moltinCache from '@/vue/moltin/cache';
import api from '@/vue/api/api';
import bugsnagClient from '@/vue/utils/bugsnag';
import { USER_ATTRIBUTE } from '@/vue/store/utils/userAttribute';
import {
  COGNITO_USER_ID_LOCAL_STORAGE_KEY,
  MOLTIN_CARD_ID_ANON,
} from '@/vue/store/utils/ls';
import Moltin from '@/vue/moltin';

const getDefaultState = () => ({
  isLoadingUser: false,
  isLoadingOrders: false,
  userInfo: null,
  isInitialized: false,
  orders: [],
  order: {
    id: null,
    products: [],
    details: {},
  },
});

const state = getDefaultState();

const formatOrder = ({ id, status, payment, meta }) => ({
  id,
  status,
  payment,
  priceFormatted: meta.display_price.with_tax.formatted,
  price: meta.display_price.with_tax.amount,
  createdAt: dayjs(meta.timestamps.created_at),
});

const mutations = {
  setIsLoadingUser(state, isLoading) {
    state.isLoadingUsers = isLoading;
  },

  setIsLoadingOrders(state, isLoading) {
    state.isLoadingOrders = isLoading;
  },

  setUserInfo(state, userInfo) {
    state.userInfo = userInfo;
  },

  setIsInitialized(state, isInitialized) {
    state.isInitialized = isInitialized;
  },

  setOrders(state, orders) {
    state.orders = orders;
  },

  setOrder(state, { id, products, details }) {
    state.order = {
      id,
      products,
      details,
    };
  },

  reset(state) {
    Object.assign(state, getDefaultState());
    moltinCache.reset();
  },
};

const callApiGateway = async ({ path, params = {} }) => {
  const session = await Auth.currentSession();
  const token = session.getIdToken().getJwtToken();

  const apiCaller = axios.create({
    baseURL: config('AWS_API_GATEWAY_BASE_URL'),
    headers: { Authorization: `Bearer ${token}` },
  });

  const response = await apiCaller.get(path, { params });

  return JSON.parse(response.data.body);
};

const actions = {
  async signUp({ dispatch }, { email, password }) {
    await Auth.signUp({
      username: email,
      password,
    });
    return dispatch('getUser');
  },

  async signOut({ dispatch, commit }) {
    await Auth.signOut();

    return dispatch('getUser').then(() => {
      localStorage.removeItem(COGNITO_USER_ID_LOCAL_STORAGE_KEY);

      commit('reset');
      commit('wishlist/reset', null, {
        root: true,
      });
      return commit('cart/reset', null, {
        root: true,
      });
    });
  },

  async signIn({ dispatch, commit }, { email, password }) {
    const user = await Auth.signIn(email, password);
    const userId = user.username;

    // Reset anonymous cart Vuex state (actual Moltin cart remains unchanged)
    commit('cart/reset', null, {
      root: true,
    });

    localStorage.setItem(COGNITO_USER_ID_LOCAL_STORAGE_KEY, userId);

    const anonCartId = localStorage.getItem(MOLTIN_CARD_ID_ANON);
    const { moltin } = await Moltin();
    const hasAnonCartItems = !!(await moltin.Cart(anonCartId).Items()).data
      .length;

    // Re-assign the anonymous cart to newly logged-in user
    if (anonCartId && hasAnonCartItems) {
      localStorage.setItem(`MOLTIN_CART_ID_${userId}`, anonCartId);
    }

    localStorage.removeItem(MOLTIN_CARD_ID_ANON);

    await dispatch('getUser');

    // Restore the state of the cart based on moltin cart ID
    return dispatch('cart/restoreCart', null, {
      root: true,
    });
  },

  forgotPassword(_, { email }) {
    return Auth.forgotPassword(email);
  },

  resetPassword(_, { email, code, password }) {
    return Auth.forgotPasswordSubmit(email, code, password);
  },

  async getUser({ commit, state }) {
    try {
      commit('setIsLoadingUser', true);
      const userInfo = await Auth.currentUserInfo();
      commit('setUserInfo', userInfo);
    } catch (e) {
      bugsnagClient.notify('Error loading users', e);
      commit('setUserInfo', null);
    } finally {
      commit('setIsLoadingUser', false);
      commit('setIsInitialized', true);
    }

    return state.userInfo;
  },

  async updateAttributes({ dispatch }, attributes) {
    const getAttr = attr => get(attributes, attr, '');

    const user = await Auth.currentAuthenticatedUser();
    await Auth.updateUserAttributes(user, {
      name: getAttr(USER_ATTRIBUTE.FIRST_NAME),
      family_name: getAttr(USER_ATTRIBUTE.LAST_NAME),
      address: JSON.stringify({
        companyName: getAttr(USER_ATTRIBUTE.COMPANY_NAME),
        street: getAttr(USER_ATTRIBUTE.STREET),
        city: getAttr(USER_ATTRIBUTE.CITY),
        postcode: getAttr(USER_ATTRIBUTE.POSTCODE),
        phoneNumber: getAttr(USER_ATTRIBUTE.PHONE_NUMBER),
        vatNumber: getAttr(USER_ATTRIBUTE.VAT_NUMBER),
      }),
    });
    return dispatch('getUser');
  },

  async getOrders({ commit }) {
    try {
      commit('setIsLoadingOrders', true);
      const orders = await callApiGateway({ path: '/user-orders/' });
      commit('setOrders', orders);
    } catch (e) {
      commit('setOrders', []);
      bugsnagClient.notify('Error loading orders', e);
    } finally {
      commit('setIsLoadingOrders', false);
    }
  },

  async getOrder({ commit }, { orderId }) {
    try {
      commit('setIsLoadingOrders', true);
      const { items, order } = await callApiGateway({
        path: '/order-items/',
        params: { orderId },
      });

      const wpProductIds = items.map(({ sku }) => getWpProductIdBySku(sku));
      const products = await api.fetch('productsByIds', wpProductIds);

      commit('setOrder', {
        id: orderId,
        products: products.data,
        details: order,
      });
    } catch (e) {
      commit('setOrders', {
        id: null,
        products: [],
        details: {},
      });
      bugsnagClient.notify('Error loading orders', e);
    } finally {
      commit('setIsLoadingOrders', false);
    }
  },
};

const getters = {
  isLoadingUser: ({ isLoadingUser }) => isLoadingUser,
  isLoadingOrders: ({ isLoadingOrders }) => isLoadingOrders,
  isInitialized: ({ isInitialized }) => isInitialized,
  userInfo: ({ userInfo }) => userInfo,
  email: ({ userInfo }) => (userInfo ? userInfo.attributes.email : null),
  moltinCustomerId: ({ userInfo }) => {
    if (!userInfo) {
      return null;
    }

    return userInfo.attributes['custom:moltinCustomerId'];
  },
  attributes: ({ userInfo }) => {
    const getAttr = attr => get(userInfo.attributes, attr, '');

    const defaults = Object.keys(USER_ATTRIBUTE).reduce(
      (acc, field) => ({
        ...acc,
        [field]: '',
      }),
      {}
    );

    const getAddress = () => {
      try {
        return JSON.parse(getAttr('address') || '{}');
      } catch (e) {
        return {};
      }
    };

    const address = getAddress();
    const getAddressAttr = attr => get(address, attr, '');

    const parsed = {
      [USER_ATTRIBUTE.FIRST_NAME]: getAttr('name'),
      [USER_ATTRIBUTE.LAST_NAME]: getAttr('family_name'),
      [USER_ATTRIBUTE.EMAIL]: getAttr('email'),
      [USER_ATTRIBUTE.COMPANY_NAME]: getAddressAttr('companyName'),
      [USER_ATTRIBUTE.STREET]: getAddressAttr('street'),
      [USER_ATTRIBUTE.CITY]: getAddressAttr('city'),
      [USER_ATTRIBUTE.POSTCODE]: getAddressAttr('postcode'),
      [USER_ATTRIBUTE.VAT_NUMBER]: getAddressAttr('vatNumber'),
      [USER_ATTRIBUTE.PHONE_NUMBER]: getAddressAttr('phoneNumber'),
    };

    return {
      ...defaults,
      ...parsed,
    };
  },
  orders: ({ orders }) => orders.map(formatOrder),
  order: ({ order }) => ({
    details: !isEmpty(order.details) ? formatOrder(order.details) : {},
    id: order.id,
    products: order.products,
  }),
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
