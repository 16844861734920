import Vue from 'vue';
import { rtdbPlugin } from 'vuefire';
import AsyncComputed from 'vue-async-computed';
import VueAnalytics from 'vue-analytics';
import { mapActions } from 'vuex';
import router from './vue/router';
import store from './vue/store';
import app from './vue/components/app.vue';
import VueGoogleMapsLoader from './vue/plugins/VueGoogleMaps';
import VueIsMobile from './vue/plugins/VueIsMobile';
import VueBugsnag from './vue/plugins/VueBugsnag';
import config from './vue/utils/config';

Vue.use(rtdbPlugin);
Vue.use(VueGoogleMapsLoader);
Vue.use(VueIsMobile);
Vue.use(AsyncComputed);
Vue.use(VueBugsnag);
Vue.use(VueAnalytics, {
  id: config('googleAnalytics'),
  router,
});

/* eslint-disable no-new */
new Vue({
  router,
  store,
  el: '#js--app',

  async created() {
    await this.getUser();
    return this.restoreCart();
  },

  methods: {
    ...mapActions('user', ['getUser']),
    ...mapActions('cart', ['restoreCart']),
  },

  render(createElement) {
    return createElement(app);
  },
});
