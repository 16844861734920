<template>
  <div
    :class="{
      'cart--hidden': isHidden,
    }"
    class="cart"
  >
    <div class="cart__wrapper">
      <CartList />

      <router-link
        :class="{
          'button--loading': loading,
        }"
        :to="{ name: 'checkout-cart' }"
        class="button button--large button--full button--alt button--no-radius"
        @click.native="buttonOnClick"
      >
        Przejdź do koszyka
      </router-link>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import CartList from '../cart-list/cart-list.vue';

const { mapGetters } = createNamespacedHelpers('cart');

export default {
  name: 'Cart',
  components: {
    CartList,
  },
  data: () => ({
    isHidden: false,
  }),
  computed: {
    ...mapGetters(['loading']),
  },
  methods: {
    buttonOnClick() {
      this.isHidden = true;
      setTimeout(() => {
        this.isHidden = false;
      }, 100);
    },
  },
};
</script>
