import api from '../../api/api';

const state = {
  categories: [],
  products: [],
  productsTotal: 0,
  backgroundImage: {
    large: '',
    medium: '',
    small: '',
  },
};

const getters = {
  products(state) {
    return state.products;
  },
  productsCount(state) {
    return state.products.length;
  },
  productsTotal(state) {
    return state.productsTotal;
  },
};

const mutations = {
  setBackgroundImage(state, { data }) {
    state.backgroundImage = data.banner;
  },
  appendProducts(state, { data }) {
    data.forEach(product => {
      state.products.push(product);
    });
  },
  setProductsTotal(state, { total }) {
    state.productsTotal = total;
  },
};

const actions = {
  fetchFeaturedProducts({ commit }, offset = 0) {
    return api
      .fetch('products', {
        offset,
        per_page: 20,
        metaQuery: {
          'show-on-front-page': 1,
        },
      })
      .then(response => {
        commit('appendProducts', response);
        commit('setProductsTotal', response);
      });
  },
  fetchBackgroundImage({ commit }) {
    return api.fetch('frontPage').then(response => {
      commit('setBackgroundImage', response);
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
