<template>
  <div>
    <CategoriesTemplate v-if="!loading" :loading="false">
      <template v-slot:list-items>
        <li
          v-for="category in categories"
          :key="category.id"
          class="categories__item"
        >
          <router-link
            :to="{
              name: 'category',
              params: {
                category: category.slug,
              },
            }"
            class="categories__link"
          >
            <h3 class="categories__name">
              {{ category.name }}
            </h3>

            <LazyImage
              :small-image="category.image.tiny.url"
              :large-image="category.image.large.url"
              :alt="category.name"
              class="categories__image"
            />
          </router-link>
        </li>
      </template>
    </CategoriesTemplate>

    <CategoriesLoader v-if="loading" />
  </div>
</template>

<script>
import LazyImage from '../lazy-image/lazy-image.vue';
import CategoriesTemplate from './categories-template.vue';
import CategoriesLoader from './categories-loader.vue';

export default {
  name: 'Categories',
  components: {
    LazyImage,
    CategoriesTemplate,
    CategoriesLoader,
  },
  props: {
    loading: {
      type: Boolean,
      default: true,
    },
    categories: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
