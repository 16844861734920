import Vue from 'vue';
import VueMeta from 'vue-meta';
import Router from 'vue-router';
import routes from './routes';
import store from '../store';

Vue.use(Router);
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true,
});

const router = new Router({
  routes,
  mode: 'history',
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }

    return {
      x: 0,
      y: 0,
    };
  },
});

router.beforeEach((to, from, next) => {
  store.commit('global/setLoading', { loading: true });
  next();
});

router.afterEach(() => {
  store.commit('global/setLoading', { loading: false });
});

export default router;
