import bugsnagClient from '../utils/bugsnag';

const VueBugsnag = {
  install(Vue) {
    Vue.mixin({
      beforeCreate() {
        this.$bugsnag = bugsnagClient;
      },
    });
  },
};

export default VueBugsnag;
