import { Auth } from 'aws-amplify';
import config from '@/vue/utils/config';

Auth.configure({
  identityPoolId: config('COGNITO_IDENTITY_POOL_ID'),
  userPoolId: config('COGNITO_USER_POOL_ID'),
  userPoolWebClientId: config('COGNITO_USER_POOL_WEB_CLIENT_ID'),
  region: config('COGNITO_REGION'),
});

export default Auth;
