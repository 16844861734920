import isUndefined from 'lodash/isUndefined';
import {
  addProduct,
  getChildProducts,
  getProductBySize,
  getProductsByMoltinIds,
  removeProduct,
} from '@/vue/moltin/helpers';
import Moltin from '@/vue/moltin';

const getDefaultState = () => ({
  productsIds: [],
  loading: false,
  products: [],
});

const state = getDefaultState();

const mutations = {
  add(state, moltinId) {
    state.productsIds.push(moltinId);
  },
  remove(state, moltinId) {
    state.productsIds = state.productsIds.filter(
      productId => productId !== moltinId
    );
  },
  setProducts(state, products) {
    state.products = products;
  },
  setLoading(state, isEnabled) {
    state.loading = isEnabled;
  },
  reset(state) {
    Object.assign(state, getDefaultState());
  },
};

const getters = {
  productsIds: state => state.productsIds,
  count: state => state.productsIds.length,
  moltinProductInCart: state => moltinProductId => {
    const product = state.productsIds.find(
      productId => productId === moltinProductId
    );
    return !isUndefined(product);
  },
  inCart: state => async ({ id, size }) => {
    const moltinProduct = await getProductBySize(id, size);
    const product = state.productsIds.find(
      productId => productId === moltinProduct.data.id
    );

    return !isUndefined(product);
  },
  loading: state => state.loading,
  products: state => state.products,
};

const actions = {
  async add({ commit, getters }, { id, size }) {
    commit('setLoading', true);

    const moltinProduct = await getProductBySize(id, size);
    const moltinProductId = moltinProduct.data.id;

    const inCart = getters.moltinProductInCart(moltinProductId);

    if (inCart) {
      throw new Error(`product ${moltinProductId} already exist in cart`);
    }

    await addProduct(moltinProductId);

    commit('add', moltinProductId);
    commit('setProducts', await getProductsByMoltinIds(getters.productsIds));
    commit('setLoading', false);
  },
  async update({ getters, commit }, { id, size }) {
    commit('setLoading', true);

    // Check if any size of the given product is in the cart
    const childProducts = await getChildProducts(id);
    const moltinProductToRemove = childProducts.find(({ data }) =>
      getters.moltinProductInCart(data.id)
    );
    const inCart = !isUndefined(moltinProductToRemove);

    if (!inCart) {
      commit('setLoading', false);
      throw new Error(`product ${id} doesn't exist in cart`);
    }

    const moltinProductToAdd = await getProductBySize(id, size);

    await Promise.all([
      removeProduct(moltinProductToRemove.data.id),
      addProduct(moltinProductToAdd.data.id),
    ]);

    commit('remove', moltinProductToRemove.data.id);
    commit('add', moltinProductToAdd.data.id);
    commit('setProducts', await getProductsByMoltinIds(getters.productsIds));
    commit('setLoading', false);
  },
  async remove({ commit, getters }, id) {
    commit('setLoading', true);

    const childProducts = await getChildProducts(id);
    const moltinProduct = childProducts.find(({ data }) =>
      getters.moltinProductInCart(data.id)
    );

    if (isUndefined(moltinProduct)) {
      commit('setLoading', false);
      return;
    }

    const moltinProductId = moltinProduct.data.id;

    await removeProduct(moltinProductId);

    commit('remove', moltinProductId);
    commit('setProducts', await getProductsByMoltinIds(getters.productsIds));
    commit('setLoading', false);
  },
  async restoreCart({ commit }) {
    const { moltin, cartId } = await Moltin();
    const cart = await moltin.Cart(cartId).Items();
    const moltinProductIds = cart.data.map(
      ({ product_id: moltinProductId }) => moltinProductId
    );

    moltinProductIds.forEach(moltinProductId => commit('add', moltinProductId));
    commit('setProducts', await getProductsByMoltinIds(moltinProductIds));
  },
  async deleteCart({ commit }) {
    commit('setLoading', true);

    const { moltin, cartId } = await Moltin();
    await moltin.Cart(cartId).Delete();

    commit('reset');
    commit('setLoading', false);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
