import { sha256 } from 'js-sha256';

export default (namespace = '') => ({
  store: {},
  createKey(key, args) {
    return sha256(JSON.stringify([namespace, key, args]));
  },
  set(key, args, returnValue) {
    const namespacedKey = this.createKey(key, args);
    this.store[namespacedKey] = returnValue;
  },
  get(key, args) {
    const namespacedKey = this.createKey(key, args);
    if (!this.store[namespacedKey]) {
      return false;
    }
    return this.store[namespacedKey];
  },
  reset() {
    this.store = {};
  },
});
