<template>
  <div class="site-footer">
    <div class="container">
      <div class="site-footer__wrapper">
        <p class="site-footer__copyrights">
          Wszystkie prawa zastrzeżone
        </p>

        <ul class="site-footer__links">
          <li class="site-footer__item">
            <a
              class="site-footer__link site-footer__link--fb"
              href="https://www.facebook.com/photogenium/"
              target="_blank"
              rel="noopener nofollow"
            >
              <span class="sr-only">
                Facebook
              </span>
            </a>
          </li>
          <li class="site-footer__item">
            <router-link
              :to="{ name: 'terms-of-use' }"
              class="site-footer__link site-footer__link--has-separator"
            >
              <span class="site-footer__link-text">Regulamin</span>
            </router-link>
          </li>
          <li class="site-footer__item">
            <router-link
              :to="{ name: 'license' }"
              class="site-footer__link site-footer__link--has-separator"
            >
              <span class="site-footer__link-text">Licencja</span>
            </router-link>
          </li>
          <li class="site-footer__item">
            <a
              class="site-footer__link site-footer__link--has-separator"
              href="mailto:info@fotoregiony.pl"
            >
              <span class="site-footer__link-text">Kontakt</span>
            </a>
          </li>
        </ul>
        <!-- /.site-footer__links -->
      </div>
      <!-- /.site-footer__wrapper -->
    </div>
    <!-- /.container -->
  </div>
  <!-- /.site-footer -->
</template>

<script>
export default {
  name: 'SiteFooter',
};
</script>
