<template>
  <VueAutosuggest
    ref="autocomplete"
    v-model="searchPhrase"
    :class="autosuggestClass"
    :get-suggestion-value="getValue"
    :input-props="{
      id: randomId,
      placeholder: 'Szukaj miejsc, widokow, lokalizacji…',
      autocomplete: 'nope',
      class: 'autosuggest__input',
    }"
    :suggestions="[
      {
        data: options,
      },
    ]"
    :should-render-suggestions="shouldRenderSuggestions"
    @selected="onSelected"
    @input="inputOnChange"
  >
    <template slot="after-input">
      <button class="autosuggest__submit" @click="searchButtonOnClick">
        <span class="sr-only">
          Szukaj
        </span>
        <span :class="submitIconClass">
          <span class="submit-icon__circle" />
          <span class="submit-icon__arrow" />
        </span>
      </button>
    </template>

    <template slot-scope="{ suggestion }">
      <template v-if="suggestion.item.tooShort">
        <span class="autosuggest__item-label">
          Wpisz co najmniej 3 znaki.
        </span>
      </template>

      <template v-else>
        <span class="autosuggest__item-label">
          {{ suggestion.item.name }}
        </span>
        <span class="autosuggest__item-count">
          {{ suggestion.item.count }} zdjęć
        </span>
      </template>
    </template>
  </VueAutosuggest>
</template>

<script>
import { VueAutosuggest } from 'vue-autosuggest';
import { mapActions, mapState } from 'vuex';
import debounce from 'lodash/debounce';
import get from 'lodash/get';

export default {
  name: 'SearchForm',
  components: {
    VueAutosuggest,
  },
  props: {
    size: {
      type: String,
      required: true,
      validator(size) {
        const validValues = ['large', 'small'];

        return validValues.indexOf(size) > -1;
      },
    },
  },
  data() {
    return {
      minSearchLength: 3,
      loading: false,
      searchPhrase: '',
    };
  },
  computed: {
    ...mapState('search', ['searchTerms']),
    randomId() {
      return `search-by-tags-${Math.random()}`;
    },
    autosuggestClass() {
      return {
        autosuggest: true,
        [`autosuggest--${this.size}`]: true,
        'autosuggest--loading': this.loading,
      };
    },
    submitIconClass() {
      return {
        'submit-icon': true,
        [`submit-icon--${this.size}`]: true,
      };
    },
    hasResults() {
      return this.searchTerms.length > 0;
    },
    searchPhraseTooShort() {
      return this.searchPhrase.length < this.minSearchLength;
    },
    hasSearchPhrase() {
      return this.searchPhrase.length > 0;
    },
    options() {
      if (!this.hasSearchPhrase) {
        return [];
      }

      if (this.searchPhraseTooShort) {
        return [
          {
            tooShort: true,
          },
        ];
      }

      if (!this.hasResults && !this.searchPhraseTooShort) {
        return [];
      }

      return this.searchTerms;
    },
  },
  watch: {
    $route() {
      this.setSearchPhraseBasedOnRoute();
    },
  },
  created() {
    this.setSearchPhraseBasedOnRoute();
  },
  methods: {
    ...mapActions('search', ['fetchSearchTerms']),
    setSearchPhraseBasedOnRoute() {
      this.searchPhrase = get(this.$route, 'query.searchPhrase') || '';
    },
    getValue({ item }) {
      return item.name;
    },
    async inputOnChange(searchPhrase) {
      this.searchPhrase = searchPhrase || '';

      if (this.searchPhraseTooShort) {
        return;
      }

      this.fetchDebounced(searchPhrase);
    },
    onSelected(selected) {
      const searchPhrase = selected ? selected.item.name : this.searchPhrase;

      if (!searchPhrase) {
        return;
      }

      this.goToSearchResults(searchPhrase);
    },
    searchButtonOnClick() {
      this.goToSearchResults(this.searchPhrase);
    },
    goToSearchResults(searchPhrase) {
      this.$router.push({
        name: 'search-results',
        query: {
          searchPhrase,
        },
      });
    },
    shouldRenderSuggestions(size, loading) {
      return size >= 0 && !loading;
    },
    async fetch(searchPhrase) {
      this.loading = true;
      await this.fetchSearchTerms(searchPhrase);
      this.loading = false;
    },
    fetchDebounced: debounce(function debounceSearchTerms(searchPhrase) {
      this.fetch(searchPhrase);
    }, 300),
  },
};
</script>
