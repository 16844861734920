import api from '../../api/api';
import mapTaxonomies from '../utils/mapTaxonomies';

/* eslint-disable camelcase */

const state = {
  products: [],
  term: {},
  categories: [],
  total: 0,
  productsTotal: 0,
  productsPages: 0,
};

const getters = {
  products(state) {
    return state.products;
  },
  total(state) {
    return state.total;
  },
};

const mutations = {
  setProducts(state, { data, total, pages }) {
    state.products = data;
    state.productsTotal = total;
    state.productsPages = pages;
  },
  setCategories(state, { data, total }) {
    state.categories = data;
    state.total = total;
  },
  setTerm(state, { data }) {
    state.term = data;
  },
};

const actions = {
  async fetchProducts(
    { commit },
    { taxonomies = {}, perPage = 20, page = 1, premiumOnly = false }
  ) {
    const taxonomyQuery = mapTaxonomies({
      ...taxonomies,
      quality: premiumOnly ? 'premium' : false,
    });

    const response = await api.fetch('postsByTaxonomy', taxonomyQuery, {
      per_page: perPage,
      page,
    });

    commit('setProducts', response);

    return response;
  },
  async fetchCategories({ commit }) {
    const response = await api.fetch('terms', 'type');
    commit('setCategories', response);
    return response;
  },
  async fetchTerm({ commit }, { taxonomy, term }) {
    const response = await api.fetch('term', taxonomy, term);
    commit('setTerm', response);
    return response;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
