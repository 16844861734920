<template>
  <div :class="{ 'categories--loading': loading }" class="categories">
    <ul class="categories__list">
      <slot name="list-items" />
    </ul>
  </div>
  <!-- /.categories -->
</template>

<script>
export default {
  name: 'CategoriesTemplate',
  props: {
    loading: {
      type: Boolean,
      default: true,
      required: false,
    },
  },
};
</script>
