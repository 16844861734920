<template>
  <div
    :class="{
      'site-header--short': !searchFormEnabled,
    }"
    class="site-header"
  >
    <h1 class="site-header__name">
      <router-link :to="{ name: 'home' }" class="site-header__link">
        Fotoregiony.pl
      </router-link>
    </h1>
    <!-- /.site-header__name -->

    <div v-if="searchFormEnabled" class="site-header__search">
      <Search-Form size="small" />
    </div>
    <!-- /.site-header__search -->

    <nav class="site-header__menu">
      <ul class="main-menu">
        <li class="main-menu__item">
          <router-link
            :to="{ name: 'categories-page' }"
            class="main-menu__link"
          >
            <span class="main-menu__text" data-content="Kategorie">
              Kategorie
            </span>
          </router-link>
        </li>
        <li class="main-menu__item">
          <router-link :to="searchResultsRoute" class="main-menu__link">
            <span class="main-menu__text" data-content="Mapa">
              Mapa
            </span>
          </router-link>
        </li>
        <li class="main-menu__item">
          <router-link
            :class="{
              'main-menu__link--heartbeat': heartBeat,
            }"
            :to="wishlistRoute"
            class="main-menu__link main-menu__link--ico main-menu__link--wishlist"
          >
            <span class="main-menu__text" data-content="Ulubione zdjęcia">
              Ulubione zdjęcia
            </span>
          </router-link>
        </li>
        <li class="main-menu__item">
          <router-link :to="{ name: 'checkout-cart' }" class="main-menu__link">
            <span class="main-menu__cart">
              <span class="sr-only">
                Koszyk
              </span>
              <span class="main-menu__count">
                {{ count }}
              </span>
            </span>
          </router-link>

          <div v-show="count > 0" class="main-menu__dropdown">
            <Cart />
          </div>
        </li>
        <li class="main-menu__item">
          <router-link :to="{ name: 'my-account' }" class="main-menu__link">
            <span class="main-menu__text" :data-content="myAccountText">
              {{ myAccountText }}
            </span>
          </router-link>
        </li>
      </ul>
      <!-- /.main-menu -->
    </nav>
    <!-- /.site-header__menu -->
  </div>
  <!-- /.site-header -->
</template>

<script>
import { mapGetters } from 'vuex';
import SearchForm from '../search-form/search-form.vue';
import Cart from '../cart/cart.vue';
import eventBus from '../../utils/eventBus';

export default {
  name: 'SiteHeader',
  components: {
    SearchForm,
    Cart,
  },
  props: {
    searchFormEnabled: {
      required: false,
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      heartBeat: false,
      listId: '',
    };
  },
  computed: {
    ...mapGetters('cart', ['count']),
    ...mapGetters('user', ['email']),
    searchResultsRoute() {
      return {
        name: 'search-results',
        query: {
          layout: 'map',
        },
      };
    },
    wishlistRoute() {
      return {
        name: 'wishlist',
        params: {
          listId: this.listId,
        },
      };
    },
    myAccountText() {
      return this.email || 'Moje Konto';
    },
  },
  created() {
    this.setListId();

    this.$store.subscribe(({ type }) => {
      if (type !== 'wishlist/addProduct') {
        return;
      }
      this.heartBeat = true;
      setTimeout(() => {
        this.heartBeat = false;
      }, 2000);
    });

    eventBus.$on('wishlist-id-updated', this.setListId);
  },
  methods: {
    setListId() {
      this.listId = localStorage.getItem('wishlistId');
    },
  },
};
</script>
