import get from 'lodash/get';

export default prop => {
  const key = `VUE_APP_${prop}`;
  const value = get(process.env, key);

  if (value === undefined) {
    throw new Error(`${prop} does not exist in the config file`);
  }

  return value;
};
