<template>
  <div class="site">
    <div class="site__cookies">
      <Cookies />
    </div>

    <header class="site__header">
      <site-header :search-form-enabled="searchFormEnabled" />
    </header>
    <!-- /.site__header -->

    <section
      class="site__content"
      :class="{
        'site__content--loading': loading,
      }"
    >
      <router-view />
    </section>

    <footer class="site__footer">
      <site-footer />
    </footer>
  </div>
</template>

<style lang="scss">
@import '../../scss/app';
</style>

<script>
import { mapGetters } from 'vuex';
import SiteHeader from './site-header/site-header.vue';
import SiteFooter from './site-footer/site-footer.vue';
import Cookies from './cookies/cookies.vue';

export default {
  name: 'App',
  components: {
    SiteHeader,
    SiteFooter,
    Cookies,
  },
  metaInfo: {
    title: 'fotoregiony.pl',
    titleTemplate: '%s | fotoregiony.pl',
    meta: [
      {
        name: 'description',
        content: 'Ponad 6 000 profesjonalnych zdjęć z Beskidów',
      },
    ],
  },
  computed: {
    ...mapGetters('global', ['loading']),
    searchFormEnabled() {
      return this.$route.name !== 'home';
    },
  },
};
</script>
