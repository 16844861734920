<template>
  <v-lazy-image
    :alt="alt"
    :class="classes"
    :src-placeholder="smallImage"
    :src="largeImage"
  />
</template>

<script>
import VLazyImage from 'v-lazy-image/v2';

export default {
  name: 'LazyImage',
  components: {
    VLazyImage,
  },
  props: {
    smallImage: {
      type: String,
      required: true,
    },
    largeImage: {
      type: String,
      required: true,
    },
    alt: {
      type: String,
      required: true,
    },
    fitToParent: {
      type: Boolean,
      required: false,
      default: false,
    },
    cover: {
      type: Boolean,
      required: false,
      default: false,
    },
    stretchHorizontal: {
      type: Boolean,
      required: false,
      default: false,
    },
    stretchVertical: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      loaded: false,
    };
  },
  computed: {
    image() {
      if (this.loaded) {
        return this.largeImage;
      }
      return this.smallImage;
    },
    classes() {
      return {
        'lazy-image': true,
        'lazy-image--loading': this.loading,
        'lazy-image--fit-to-parent': this.fitToParent,
        'lazy-image--cover': this.cover,
        'lazy-image--stretch-horizontal': this.stretchHorizontal,
        'lazy-image--stretch-vertical': this.stretchVertical,
      };
    },
  },
};
</script>

<style scoped lang="scss">
.lazy-image {
  /* Force hw acceleration to fix a weird shadow effect on Safari iOS (caused by the blur filter) */
  transform: translate3d(0, 0, 0);

  &--fit-to-parent {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }

  &--stretch-horizontal {
    width: 100%;
  }

  &--stretch-vertical {
    height: 100%;
  }

  &--center {
    display: block;
    margin: 0 auto;
  }

  &--cover {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
  }

  &.v-lazy-image {
    filter: blur(5px);
    will-change: filter;

    &-loaded {
      filter: blur(0);
      transition: 0.3s;
    }
  }

  &.v-lazy-image#{&}--fit-to-parent {
    min-width: 100%;
    min-height: 100%;
  }

  &.v-lazy-image-loaded#{&}--fit-to-parent {
    min-width: auto;
    min-height: auto;
  }
}
</style>
