<template>
  <div class="images-grid">
    <div v-for="(row, index) in rows" :key="index" class="images-grid__row">
      <div
        v-for="image in row"
        :key="image.slug"
        :class="imagesGridItemClasses(row, image)"
        class="images-grid__item"
        @mouseenter="$emit('imageMouseEnter', image)"
        @mouseleave="$emit('imageMouseLeave', image)"
      >
        <router-link
          :to="{
            name: 'single-product',
            params: {
              slug: image.slug,
            },
          }"
          class="images-grid__link"
        >
          <h2
            :class="{
              'images-grid__title--truncated': compactMode,
              'images-grid__title--premium-image': image.isPremium,
            }"
            class="images-grid__title"
          >
            {{ image.title }}
          </h2>

          <div v-if="image.isPremium" class="images-grid__premium">
            <p
              :class="{
                'premium-ico--small': !fixedLayout,
              }"
              class="premium-ico"
            >
              <span class="premium-ico__star">
                Premium
              </span>
              <span class="premium-ico__tooltip">Premium</span>
            </p>
          </div>

          <LazyImage
            :small-image="image.thumbnail"
            :large-image="image.image"
            :alt="image.title"
            :cover="true"
          />
        </router-link>

        <div
          v-if="hasToolbar"
          :class="{
            'images-grid__image-actions--active': isToolbarActive(image.id),
          }"
          class="images-grid__image-actions"
        >
          <ImageActions
            :id="image.id"
            :compact-mode="compactMode"
            :hide-cart-text="compactMode || image.displayMode === 'portrait'"
          />
        </div>
      </div>
      <!-- /.images-grid__item -->
    </div>
    <!-- /.images-grid__row -->
  </div>
  <!-- /.images-grid -->
</template>

<script>
import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import { mapGetters } from 'vuex';
import LazyImage from '../lazy-image/lazy-image.vue';
import ImageActions from '../image-actions/image-actions.vue';

export default {
  name: 'ImagesGrid',
  components: {
    LazyImage,
    ImageActions,
  },
  props: {
    images: {
      type: Array,
      default: () => [],
      required: true,
    },
    hasToolbar: {
      type: Boolean,
      default: true,
    },
    fixedLayout: {
      type: Boolean,
      default: true,
    },
    compactMode: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    rows() {
      let row = 0;
      let spaceConsumed = 0;

      // Define how much vertical with does image take
      const sizes = {
        panoramic: 1 / 2,
        landscape: 1 / 3,
        portrait: 1 / 6,
      };

      const products = this.images.map(({ title, id, attributes, slug }) => {
        const isPanoramic = attributes.panoramic.slug === 'yes';

        // The orientation `depends` on the `orientation` property and on the `panoramic` property.
        const orientation = (() => {
          // For panoramic images always return `landscape`
          if (isPanoramic) {
            return 'landscape';
          }
          return attributes.orientation.slug; // 'landscape' || 'portrait'
        })();

        // ceil elementWidth wit 3 decimal places precision because 1/3 * 3 < 1;
        const elementWidth = isPanoramic ? sizes.panoramic : sizes[orientation];
        const displayMode = isPanoramic ? 'panoramic' : orientation;

        // Lets add each element width to the total width
        spaceConsumed += elementWidth;
        // If the total width reaches 1 it means that there's no more space left in the row
        if (spaceConsumed > 1) {
          // So let's create a new row
          row += 1;
          // And reset the space consumed
          spaceConsumed = elementWidth;
        }

        const imageUrlPath = isPanoramic
          ? 'image.large.url'
          : 'image.medium.url';

        // TODO: attributes.image.tiny.url should never be undefined
        // Fix on the backend.
        return {
          row,
          displayMode,
          orientation,
          id,
          slug,
          title: title.rendered,
          thumbnail: get(attributes, 'image.tiny.url') || '',
          image: get(attributes, imageUrlPath) || '',
          isPremium: attributes.quality.slug === 'premium',
        };
      });

      return groupBy(products, 'row');
    },
    ...mapGetters('wishlist', {
      productsIdsInWishlist: 'ids',
    }),
    ...mapGetters('cart', {
      productsInCart: 'products',
    }),
  },
  methods: {
    isToolbarActive(id) {
      const inCart =
        this.productsInCart.find(product => product.id === id) !== undefined;
      const inWishlist = this.productsIdsInWishlist.indexOf(id) !== -1;

      return inCart || inWishlist;
    },
    imagesGridItemClasses(row, image) {
      const inRow = row.length < 3 ? 3 : row.length;

      return {
        'images-grid__item--portrait': image.displayMode === 'portrait',
        'images-grid__item--landscape': image.displayMode === 'landscape',
        'images-grid__item--panoramic': image.displayMode === 'panoramic',
        'images-grid__item--fixed': this.fixedLayout,
        'images-grid__item--fluid': !this.fixedLayout,
        [`images-grid__item--in-row-${inRow}`]: true,
      };
    },
  },
};
</script>
