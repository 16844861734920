import debounce from 'lodash/debounce';

let currentBreakpoint = null;

const recalculateCurrentBreakpoint = () => {
  const body = document.querySelector('body');

  if (!body) {
    return;
  }

  currentBreakpoint = window
    .getComputedStyle(body, ':before')
    .getPropertyValue('content')
    .replace(/"/g, '');
};

/**
 * @param breakpoints
 * @returns {boolean}recalculateCurrentBreakpoint
 */
const is = breakpoints => {
  if (!currentBreakpoint) {
    recalculateCurrentBreakpoint();
  }

  return breakpoints.includes(currentBreakpoint);
};
/**
 * @returns {string}
 */
const getCurrent = () => currentBreakpoint;

window.addEventListener('resize', debounce(recalculateCurrentBreakpoint, 300));

export default {
  is,
  getCurrent,
};
