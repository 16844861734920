import isNumber from 'lodash/isNumber';
import props from './props';

export default {
  name: 'SkeletonBase',
  mixins: [props],
  props: {
    baseClass: {
      required: true,
      type: String,
    },
  },
  render() {
    return this.$scopedSlots.default({
      classes: this.classes,
    });
  },
  computed: {
    classes() {
      const baseClasses = {
        [this.baseClass]: true,
        [`${this.baseClass}--theme-${this.theme}`]: true,
        [`${this.baseClass}--fit-to-parent`]: this.fitToParent,
        [`${this.baseClass}--animated`]: this.animated,
        [`${this.baseClass}--lh-${this.height}`]:
          isNumber(this.height) && !this.fitToParent,
      };

      const breakpoints = Object.keys(this.height);
      const heightClasses = breakpoints.reduce((obj, breakpoint) => {
        const value = this.height[breakpoint];
        return {
          ...obj,
          [`${this.baseClass}--lh-${breakpoint}-${value}`]: !this.fitToParent,
        };
      }, {});

      return {
        ...baseClasses,
        ...heightClasses,
      };
    },
  },
};
