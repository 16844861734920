export default taxonomies => {
  const map = {
    orientation:
      taxonomies.orientation !== '' && taxonomies.orientation !== 'any'
        ? taxonomies.orientation
        : false,
    panoramic: taxonomies.panoramic === true ? 'yes' : false,
    quality: taxonomies.quality ? taxonomies.quality : false,
    type: taxonomies.type,
    city: taxonomies.city,
    place: taxonomies.place,
    season: taxonomies.season,
    'time-of-day': taxonomies.timeOfDay,
    'view-on': taxonomies.viewOn,
    'view-from': taxonomies.viewFrom,
  };

  return Object.keys(map)
    .map(taxonomy => {
      const terms = map[taxonomy];

      if (
        typeof terms === 'undefined' ||
        terms === false ||
        terms.length === 0
      ) {
        return false;
      }

      return {
        taxonomy,
        terms,
      };
    })
    .filter(taxonomy => taxonomy !== false);
};
