var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"image-actions"},[_c('div',{staticClass:"image-actions__toolbar"},[_c('WishlistButton',{staticClass:"image-actions__button image-actions__button--wishlist",class:{ 'image-actions__button--compact-mode': _vm.compactMode },attrs:{"id":_vm.id},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var inWishlist = ref.inWishlist;
var text = ref.text;
return [_c('span',{staticClass:"image-actions__ico",class:{
            'image-actions__ico--heart': inWishlist,
            'image-actions__ico--heart-outline': !inWishlist,
          },attrs:{"role":"presentation"}},[_vm._v(" "+_vm._s(text)+" ")]),_c('span',{staticClass:"image-actions__tooltip"},[_vm._v(" "+_vm._s(text)+" ")])]}}])}),_c('button',{staticClass:"image-actions__button image-actions__button--cart",class:{
        'image-actions__button--compact-mode': _vm.compactMode,
        'image-actions__button--loading': _vm.cartProcessing,
      },on:{"click":function($event){$event.preventDefault();return _vm.cartOnClick($event)}}},[_c('span',{staticClass:"image-actions__cart-text",class:{
          'image-actions__cart-text--hidden': _vm.hideCartText,
        }},[_vm._v(" "+_vm._s(_vm.cartButtonText)+" ")]),_c('span',{staticClass:"image-actions__ico",class:{
          'image-actions__ico--remove': _vm.inCart,
          'image-actions__ico--cart': !_vm.inCart,
        }},[_vm._v(" Koszyk ")]),(_vm.hideCartText)?_c('span',{staticClass:"image-actions__tooltip"},[_vm._v(" "+_vm._s(_vm.cartButtonText)+" ")]):_vm._e()])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }