import firebase from 'firebase/app';
import 'firebase/database';
import config from './config';

const settings = {
  apiKey: config('apiKey'),
  authDomain: config('authDomain'),
  databaseURL: config('databaseURL'),
  projectId: config('projectId'),
  storageBucket: config('storageBucket'),
  messagingSenderId: config('messagingSenderId'),
};

const firebaseApp = firebase.initializeApp(settings);
const db = firebaseApp.database();

const crud = {
  get(ref, child) {
    return new Promise((resolve, reject) => {
      ref
        .child(child)
        .once('value')
        .then(snapshot => {
          const { ids } = snapshot.val();
          if (!snapshot.exists() || !ids) {
            reject();
          }
          resolve(ids);
        })
        .catch(reject);
    });
  },
  insert(ref, data) {
    return ref.push(data);
  },
  update(ref, child, data) {
    return ref.child(child).update(data);
  },
};

export { firebaseApp, db, crud };
