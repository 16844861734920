import Vue from 'vue';
import Vuex from 'vuex';
import global from './modules/global';
import home from './modules/home';
import category from './modules/category';
import loader from './modules/loader';
import search from './modules/search';
import product from './modules/product';
import wishlist from './modules/wishlist/index';
import cart from './modules/cart/index';
import categories from './modules/categories';
import user from './modules/user';
import userAttributesForm from './modules/user-attributes-form';
import persistence from './persistence';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
  modules: {
    global,
    home,
    category,
    loader,
    search,
    product,
    wishlist,
    cart,
    categories,
    user,
    userAttributesForm,
  },
  plugins: [persistence.plugin],
  strict: debug,
});
