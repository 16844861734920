export default size => {
  const mapping = {
    sm: 'Mały',
    md: 'Średni',
    lg: 'Duży',
    xl: 'Bardzo duży',
  };

  return mapping[size];
};
