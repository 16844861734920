<template>
  <div :class="cartWrapperClasses" class="cart-wrapper">
    <div class="cart-wrapper__content">
      <div v-if="showColumnHeaders" class="cart-table-headers">
        <ul class="cart-table-headers__list">
          <li class="cart-table-headers__item cart-table-headers__item--image">
            Podgląd
          </li>

          <li class="cart-table-headers__item cart-table-headers__item--name">
            Tytuł
          </li>

          <li class="cart-table-headers__item cart-table-headers__item--id">
            ID
          </li>

          <li class="cart-table-headers__item cart-table-headers__item--size">
            Rozmiar
          </li>

          <li class="cart-table-headers__item cart-table-headers__item--price">
            Cena
          </li>

          <li class="cart-table-headers__item cart-table-headers__item--delete">
            <span class="sr-only">usuń</span>
          </li>
        </ul>
      </div>

      <ul :class="cartListClasses" class="cart-list">
        <li
          v-for="{
            id,
            slug,
            name,
            price,
            image,
            size,
            width,
            height,
            siblings,
          } in products"
          :key="id"
          class="cart-list__item"
        >
          <ul class="cart-list__props">
            <li class="cart-list__prop cart-list__prop--image">
              <router-link
                :to="getSingleProductRoute(slug)"
                class="cart-list__link"
              >
                <img :src="image" :alt="name" class="cart-list__image" />
              </router-link>
            </li>

            <li v-if="showTitles" class="cart-list__prop cart-list__prop--name">
              <router-link
                :to="getSingleProductRoute(slug)"
                class="cart-list__link"
              >
                {{ name }}
              </router-link>
            </li>

            <li class="cart-list__prop cart-list__prop--id">ID: {{ id }}</li>

            <li class="cart-list__prop cart-list__prop--size">
              <label :for="slug" class="sr-only">
                Rozmiar
              </label>
              <!-- TODO: use .input--select -->
              <select
                :id="slug"
                :name="slug"
                class="cart-list__select"
                @change="sizeOnChange(id, $event.target.value)"
              >
                <option
                  v-for="sibling in siblings"
                  :key="sibling.moltin.id"
                  :selected="sibling.size === size"
                  :value="sibling.size"
                >
                  {{ productSizeToHumanReadable(sibling.size) }} &mdash;
                  {{ sibling.width }}x{{ sibling.height }}px
                  <template v-if="!loading && sibling.size !== size">
                    ({{ sibling.price }})
                  </template>
                </option>
              </select>
            </li>

            <li class="cart-list__prop cart-list__prop--price">
              {{ price }}
            </li>

            <li class="cart-list__prop cart-list__prop--delete">
              <button class="cart-list__delete" @click="deleteOnClick(id)">
                Usuń
              </button>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import productSizeToHumanReadable from '../../utils/productSizeToHumanReadable';

const { mapGetters, mapActions } = createNamespacedHelpers('cart');

export default {
  name: 'CartList',
  props: {
    showTitles: {
      type: Boolean,
      default: false,
      required: false,
    },
    showColumnHeaders: {
      type: Boolean,
      default: false,
      required: false,
    },
    layout: {
      type: String,
      default: 'narrow',
      required: false,
      validator(layout) {
        const allowedValues = ['narrow', 'wide'];

        return allowedValues.includes(layout);
      },
    },
  },
  data() {
    return {
      select: [],
    };
  },
  computed: {
    ...mapGetters(['loading', 'productsIds', 'products']),
    cartListClasses() {
      return {
        'cart-list--loading': this.loading,
        [`cart-list--${this.layout}`]: true,
      };
    },
    cartWrapperClasses() {
      return {
        'cart-wrapper--loading': this.loading,
        [`cart-wrapper--${this.layout}`]: true,
      };
    },
  },
  methods: {
    ...mapActions(['update', 'remove']),
    sizeOnChange(id, size) {
      this.update({
        id,
        size,
      });
    },
    deleteOnClick(id) {
      this.remove(id);
    },
    productSizeToHumanReadable,
    getSingleProductRoute(slug) {
      return {
        name: 'single-product',
        params: {
          slug,
        },
      };
    },
  },
};
</script>
